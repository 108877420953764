<template>
  <div>
    <v-card outlined box v-if="getMobileOperatingSystem === 'unknown'">
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <div class="font-size-h4 text-center">
              Scan this QR Code from your phone
            </div>
            <div class="d-flex justify-center align-center">
              <qrcode :value="redirectLink" :options="{ width: 200 }" />
            </div>
            <div class="font-size-h4 text-center mx-7 mb-2 mt-2">
              to install our testing app or using the link below
            </div>
            <div
              @click="$router.push({ name: 'start' })"
              class="cursor-pointer primary--text bodyFont text-center"
            >
              {{ redirectLink }}
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card outlined box v-else>
      <v-card-title class="justify-center">
        Install our testing app</v-card-title
      >
      <v-card-text>
        <v-row>
          <v-col
            class="text-center"
            v-if="getMobileOperatingSystem === 'android'"
            cols="12"
            md="3"
          >
            <div>
              <a @click="openDownloadLinks('playStore')">
                <v-img
                  contain
                  height="50"
                  src="/media/svg/google-play.svg"
                  class="install-img"
                />
              </a>
            </div>
            <!--            <div class="my-4">Or</div>-->
            <!--            <div-->
            <!--              class="bold-text bodyFont mb-4 cursor-pointer primary&#45;&#45;text"-->
            <!--              @click="openDownloadLinks('huaweiStore')"-->
            <!--            >-->
            <!--              Huawei store-->
            <!--            </div>-->
            <!--            <div-->
            <!--              class="bold-text bodyFont cursor-pointer primary&#45;&#45;text"-->
            <!--              @click="openDownloadLinks('samsungStore')"-->
            <!--            >-->
            <!--              Samsung store-->
            <!--            </div>-->
          </v-col>
          <v-col v-if="getMobileOperatingSystem === 'ios'" cols="12" md="3">
            <a @click="openDownloadLinks('appStore')">
              <v-img
                contain
                height="50"
                src="/media/svg/app-store.svg"
                class="install-img"
              />
            </a>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      downloadLinks: {
        playStore: {
          link: "https://play.google.com/store/apps/details?id=testapp.io&hl=en_US&gl=US",
          title: "Google Play Store",
        },
        huaweiStore: {
          link: "https://appgallery.huawei.com/#/app/C103407753",
          title: "Huawei AppGallery",
        },
        samsungStore: {
          link: "https://galaxy.store/testappio",
          title: "Samsung Galaxy Store",
        },
        appStore: {
          link: "https://apps.apple.com/us/app/testapp-io/id1518972541",
          title: "Apple App Store",
        },
      },
    };
  },
  computed: {
    redirectLink() {
      return `${location.origin}/start`;
    },
  },
  methods: {
    openDownloadLinks(device) {
      const selectedLink = this.downloadLinks[device].link;
      if (this.getMobileOperatingSystem !== "unknown") {
        if (this.getMobileOperatingSystem === "android") {
          window.location.href = `intent://#Intent;scheme=testappio://;package=testapp.io;S.browser_fallback_url=${encodeURIComponent(
            selectedLink
          )};end`;
        } else {
          window.open(selectedLink, "_blank");
        }
      }
    },
  },
};
</script>

<style></style>
