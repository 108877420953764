<template>
  <div class="">
    <div
      style="display: flex; justify-content: space-between"
      justify="space-between"
    >
      <div>
        <v-btn
          rounded
          :class="
            activeTab == 'email' ? 'primary white--text' : 'grey primary--text'
          "
          @click="activeTab = 'email'"
          class="mr-3 text-capitalize"
          ><v-icon
            :color="activeTab === 'email' ? 'white' : 'primary'"
            left
            small
            >mail</v-icon
          >by email
        </v-btn>
        <v-btn
          rounded
          :class="
            activeTab == 'url' ? 'primary white--text' : 'grey primary--text'
          "
          value="url"
          class="ma-2 text-capitalize"
          @click="loadUrlInvites"
          ><v-icon small left :color="activeTab == 'url' ? 'white' : 'primary'"
            >link</v-icon
          >by URL
        </v-btn>
      </div>
      <div class="text-right" v-if="canManageInvite && !fromAppPage">
        <InviteMembersPanel @makeActive="setActiveTab" class="side-ptn" />
      </div>
    </div>
    <v-tabs v-model="activeTab" hide-slider>
      <v-tab-item id="email">
        <invites
          :data="emailInvites"
          :total-count="getEmailTotalCount"
          :page.sync="emailPage"
          :loader="loading"
        />
      </v-tab-item>
      <v-tab-item id="url">
        <invites
          :total-count="getUrlTotalCount"
          :page.sync="urlPage"
          :data="urlInvites"
          :loader="loading"
        />
      </v-tab-item>
      <!--  -->
    </v-tabs>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";
import { get } from "@/core/services/helper.service";
import { INVITE_BY_EMAIL } from "@/store/team/invite.module";
import { INVITE_BY_URL } from "@/store/team/invite.module";
import invites from "@/view/components/Invites/invites";
import InviteMembersPanel from "@/view/components/Invites/InviteMembersPanel.vue";
export default {
  props: {
    currentTab: {
      type: String,
      default: null,
    },
    fromAppPage: {
      type: Boolean,
      default: false
    },
    fromDashboard: {
      type: Boolean,
      default: false,
    },
    activeTabType: {
      type: Object,
      default: null
    }
  },
  components: { InviteMembersPanel, invites },
  data() {
    return {
      activeTab: "email",
      loading: false,
      emailPage: 1,
      urlPage: 1,
    };
  },
  computed: {
    ...mapGetters({
      getEmailTotalCount: "getEmailTotalCount",
      getEmailTotalList: "getEmailTotalList",
      getUrlTotalList: "getUrlTotalList",
      getUrlTotalCount: "getUrlTotalCount",
    }),
    ...mapState({
      info: (state) => get(state, "manageTeam.info", ""),
      // inviteLoader: (state) => get(state, "invite.inviteLoader", ""),
      inviteURL: (state) => get(state, "invite.inviteURL", ""),
      emailInvites: (state) => get(state, "invite.emailInvites", ""),
      urlInvites: (state) => get(state, "invite.urlInvites", ""),
    }),
  },
  watch: {
    activeTabType(val) {
      if(val.type) {
        this.setActiveTab(val)
      }
    },
    emailPage(val, oldVal) {
      let offset = (val - 1) * 10;
      let limit = val * 10;
      // checked if it is next or previous
      if (val > oldVal) {
        //  if next and all the data are already fetched
        if (this.getEmailTotalList.length > oldVal * 10) {
          this.setPreviousEmailInviteData(
            this.getEmailTotalList.slice(offset, limit)
          );
        } else {
          // if it is first time, get last id and hit api
          const lastId = this.emailInvites[this.emailInvites.length - 1].id;
          this.loadEmailInvites(lastId);
        }
      } else {
        // if it is previous then show already fetched data
        this.setPreviousEmailInviteData(
          this.getEmailTotalList.slice(offset, limit)
        );
      }
    },
    urlPage(val, oldVal) {
      let offset = (val - 1) * 10;
      let limit = val * 10;
      // checked if it is next or previous
      if (val > oldVal) {
        //  if next and all the data are already fetched
        if (this.getUrlTotalList.length > oldVal * 10) {
          this.setPreviousUrlInviteData(
            this.getUrlTotalList.slice(offset, limit)
          );
        } else {
          // if it is first time, get last id and hit api
          const lastId = this.urlInvites[this.urlInvites.length - 1].id;
          this.loadUrlInvites(lastId);
        }
      } else {
        // if it is previous then show already fetched data
        this.setPreviousUrlInviteData(
          this.getUrlTotalList.slice(offset, limit)
        );
      }
    },
  },
  created() {
    if (this.currentTab) {
      this.activeTab = this.currentTab;
    }
    if (this.fromDashboard) {
      this.loadEmailInvites();
    }
  },
  methods: {
    ...mapMutations({
      setPreviousEmailInviteData: "setPreviousEmailInviteData",
      setPreviousUrlInviteData: "setPreviousUrlInviteData",
    }),
    setActiveTab(type) {
      this.tab = 1;
      if (type.type === "email") {
        this.loadEmailInvites();
        this.activeTab = "email";
      } else {
        this.loadUrlInvites();
      }
    },
    async loadEmailInvites(lastId) {
      if (this.loading) return;
      this.loading = true;
      await this.$store
        .dispatch(INVITE_BY_EMAIL, {
          lastID: lastId && typeof lastId !== "object" ? lastId : "",
          appID: this.$route.params.app_id || "",
        })
        .catch((err) => {
          this.notifyErrorMessage({ message: err.message });
          this.loading = false;
        });
      this.loading = false;
    },
    async loadUrlInvites(lastId) {
      // we have to tabs in Invites one for urls, and one for emails
      this.activeTab = "url";

      // if ther are no urlInvites call API, else don't call teh API cuz we alraedy have the data
      if (this.loading) return;
      this.loading = true;
      await this.$store
        .dispatch(INVITE_BY_URL, {
          lastID: lastId && typeof lastId !== "object" ? lastId : "",
          appID: this.$route.params.app_id || "",
        })
        .catch((err) => {
          this.notifyErrorMessage({ message: err.message });
          this.loading = false;
        });
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
