<template>
  <div>
    <!-- <div v-if="loading">
      <loader />
    </div> -->
    <v-card flat class="mt-n8">
      <div
        class="wizard wizard-4"
        id="kt_wizard_v4"
        data-wizard-state="step-first"
        data-wizard-clickable="true"
      >
        <v-card
          color="primary radius-10 welcome-card white--text fill-width get-started-top-card overflow-hidden"
          flat
        >
          <img
            class="plant-Right"
            src="/media/svg/dashboard/Plant-Right.svg"
            alt
          />
          <img class="plant" src="/media/svg/dashboard/Plant.svg" alt />
          <v-btn
            absolute
            top
            right
            v-if="inTeam"
            id="gotoDashboard"
            link
            to="/"
            class="main transparent white--text"
            >Go to dashboard</v-btn
          >
          <div
            class="
              welcome-text
              fill-height
              d-flex
              align-center
              justify-space-between
              flex-column
              info
              transparent
            "
          >
            <h1 class="bold-text mb-5 mt-9">Get Started</h1>
            <p class="mt-2 text-center description">
              Portal will be used to upload new releases for both Android and
              iOS and inviting team members to manage or install them from our
              mobile app
            </p>
            <!--begin: Wizard Nav-->
            <div class="wizard-nav fill-width">
              <div class="wizard-steps">
                <div
                  class="wizard-step mb-0"
                  data-wizard-type="step"
                  :class="{ current: step == 1 }"
                >
                  <div
                    id="createTeamTab"
                    class="wizard-wrapper"
                    @click="step = 1"
                  >
                    <div class="wizard-number">1</div>
                    <div class="wizard-label">
                      <h1 class="wizard-title">
                        {{ !isEmpty(info) ? "Update Team" : "Create Team" }}
                      </h1>
                      <div class="wizard-desc">Required</div>
                    </div>
                  </div>
                </div>
                <div
                  class="wizard-step mb-0"
                  data-wizard-type="step"
                  id="createAppTab"
                  :class="{ current: step == 2 }"
                >
                  <div class="wizard-wrapper" @click="gotoApps">
                    <div class="wizard-number">2</div>
                    <div class="wizard-label">
                      <h1 class="wizard-title">Create App</h1>
                      <div class="wizard-desc">Optional</div>
                    </div>
                  </div>
                </div>
                <div
                  class="wizard-step mb-0"
                  data-wizard-type="step"
                  id="createInviteTab"
                  :class="{ current: step == 3 }"
                >
                  <div class="wizard-wrapper" @click="fetchMember">
                    <div class="wizard-number">3</div>
                    <div class="wizard-label">
                      <div class="wizard-title">Invite Members</div>
                      <div class="wizard-desc">Optional</div>
                    </div>
                  </div>
                </div>
                <div
                  class="wizard-step mb-0"
                  data-wizard-type="step"
                  id="createReleaseTab"
                  :class="{ current: step == 4 }"
                >
                  <div class="wizard-wrapper" @click="gotoReleases">
                    <div class="wizard-number">4</div>
                    <div class="wizard-label">
                      <div class="wizard-title">Create Release</div>
                      <div class="wizard-desc">Optional</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--end: Wizard Nav -->
          </div>
          <!--  -->
        </v-card>
        <!--begin: Wizard Body-->
        <v-card flat class="transparent overflow-hidden">
          <div class="row justify-center">
            <div class="col-xl-12">
              <div class="form mt-0 mt-lg-10">
                <div v-if="step == 1">
                  <v-row align="center" class="mt-3">
                    <v-col cols="12" md="2"></v-col>
                    <!-- <v-col cols="12" md="3">
                      <v-card flat class="radius-10 primary">
                        <v-card
                          flat
                          class="
                            white--text
                            d-flex
                            flex-column
                            align-center
                            justify-center
                            radius-10
                            primary
                            connecting-card
                            mx-auto
                          "
                        >
                          <h3>Info</h3>
                          <div>
                            <p class="mt-5 px-4">
                              This team will be holding your members, apps and
                              releases.
                            </p>
                            <p class="mt-5 px-4">
                              You can create and join multiple teams.
                            </p>
                          </div>
                          <img
                            class="plant"
                            src="/media/svg/dashboard/Plant.svg"
                            alt
                          />
                        </v-card>
                      </v-card>
                    </v-col> -->
                    <v-col cols="12" md="8">
                      <div v-if="getInactiveUserMessage && getIsUserDisabled">
                        <no-data
                          :first-text="
                            getInactiveUserMessage ||
                            'Your membership in this team is inactive, please contact the team owners/admins for more info'
                          "
                        ></no-data>
                      </div>
                      <div v-else>
                        <create-team
                          v-if="!loading"
                          :team="team"
                          :in-team="inTeam"
                          @update="updateTeam"
                          @openSubscriptionModal="openSubscriptionModal = true"
                          @next-step="step = 2"
                          @updateInTeam="inTeam = true"
                        ></create-team>
                      </div>
                    </v-col>
                    <!-- <v-col md="3" cols="12">
                      <download-app></download-app>
                    </v-col> -->
                  </v-row>
                </div>
                <div v-if="step == 2">
                  <v-row class="mt-3">
                    <v-col cols="12" md="1"></v-col>
                    <!-- <v-col cols="12" md="3">
                      <div>
                        <v-card flat class="radius-10 primary">
                          <v-card
                            flat
                            class="
                              white--text
                              d-flex
                              flex-column
                              align-center
                              justify-center
                              radius-10
                              primary
                              connecting-card
                              mx-auto
                            "
                          >
                            <h3>Info</h3>
                            <div>
                              <p class="mt-5 text-center px-4">
                                App is a holder for both your Android & iOS
                                releases.
                              </p>

                              <div class="bold-text mb-4">Key features</div>
                              <div
                                flat
                                class="white-bordered-light radius-10 mb-3"
                              >
                                Chat for each app in our
                                <a
                                  href="https://testapp.io/#download"
                                  target="_blank"
                                  class="white--text"
                                >
                                  mobile app.
                                </a>
                              </div>
                              <div
                                flat
                                class="white-bordered-light radius-10 mb-3"
                              >
                                Add team members.
                              </div>
                              <div flat class="white-bordered-light radius-10">
                                Timeline.
                              </div>
                            </div>
                            <img
                              class="plant"
                              src="/media/svg/dashboard/Plant.svg"
                              alt
                            />
                          </v-card>
                        </v-card>
                      </div>
                    </v-col> -->
                    <v-col cols="12" md="8">
                      <div>
                        <div v-if="getInactiveUserMessage && getIsUserDisabled">
                          <no-data
                            :first-text="
                              getInactiveUserMessage ||
                              'Your membership in this team is inactive, please contact the team owners/admins for more info'
                            "
                          ></no-data>
                        </div>
                        <div v-else>
                          <v-card
                            flat
                            class="radius-10 pb-12 mx-md-3"
                            :class="{
                              'pt-9 mx-0': $vuetify.breakpoint.smAndDown,
                            }"
                          >
                            <v-card-title class="text-center">
                              <!-- <h3
                                class="primary--text d-inline mx-auto mt-6 mb-7"
                              >
                                Create your first app
                              </h3> -->
                            </v-card-title>
                            <create-app-step
                              @goNext="fetchMember"
                              from-get-started
                              class="ml-md-5"
                            />
                            <v-btn
                              absolute
                              top
                              right
                              text
                              class="
                                bold-text
                                text-capitalize
                                mt-3
                                grey
                                lighten-2
                              "
                              @click="nextButtonStep2"
                            >
                              Skip
                            </v-btn>
                          </v-card>
                        </div>
                      </div>
                    </v-col>
                    <v-col cols="12" md="3">
                      <download-app></download-app>
                    </v-col>
                  </v-row>
                </div>
                <div v-if="step == 3">
                  <v-row class="mt-3">
                    <v-col cols="12" md="2"></v-col>
                    <!-- <v-col cols="12" md="3">
                      <div>
                        <v-card flat class="radius-10 primary">
                          <v-card
                            flat
                            class="
                              white--text
                              d-flex
                              flex-column
                              align-center
                              justify-center
                              radius-10
                              primary
                              connecting-card
                              mx-auto
                            "
                          >
                            <h3>Info</h3>
                            <div>
                              <p class="mt-5 text-center px-4">
                                Invite team members to access or manage your
                                apps & releases.
                              </p>

                              <div class="bold-text mb-4">Key features</div>
                              <div class="white-bordered-light radius-10 mb-2">
                                Invite by email or a public URL
                              </div>
                              <div class="white-bordered-light radius-10 mb-2">
                                Custom role & permissions for each member.
                              </div>
                              <div class="white-bordered-light radius-10">
                                Invite for a specific app.
                              </div>
                            </div>
                            <img
                              class="plant"
                              src="/media/svg/dashboard/Plant.svg"
                              alt
                            />
                          </v-card>
                        </v-card>
                      </div>
                    </v-col> -->
                    <template v-if="getIsUserDisabled">
                      <v-col cols="12" md="8">
                        <no-data :first-text="getInactiveUserMessage"></no-data>
                      </v-col>
                    </template>
                    <template v-else>
                      <v-col cols="12" md="8">
                        <v-card
                          height="250"
                          v-if="haveInvites"
                          flat
                          class="radius-10 pb-12 mx-md-3"
                          :class="{
                            'pt-9': $vuetify.breakpoint.smAndDown,
                            'px-5': $vuetify.breakpoint.mdAndDown,
                          }"
                        >
                          <v-card-title class="text-center">
                            <h3
                              id="inviteDescriptionText"
                              class="darkGery--text d-inline mx-auto mt-6 mb-7"
                            >
                              Invite team members
                              <br /><small class="text-muted"
                                >(Developers, QA, Managers,....)</small
                              >
                            </h3>
                          </v-card-title>
                          <v-card-text
                            class="
                              d-flex
                              justify-center
                              align-center
                              text-center
                            "
                            style="height: 46%"
                          >
                            <div>
                              <invite-members-panel
                                @makeActive="makeActiveType"
                              />
                            </div>
                          </v-card-text>
                          <v-btn
                            absolute
                            top
                            right
                            id="skipOfInvite"
                            text
                            class="
                              bold-text
                              text-capitalize
                              mt-3
                              grey
                              lighten-2
                            "
                            @click="step = 4"
                          >
                            Skip
                          </v-btn>
                        </v-card>
                        <invites-list
                          :current-tab="activeTab"
                          class="mx-3"
                          v-else
                        />
                      </v-col>
                    </template>
                    <!-- <v-col md="3" cols="12">
                      <download-app></download-app>
                    </v-col> -->
                  </v-row>
                  <!-- check this -->
                </div>
                <div v-if="step == 4">
                  <v-row class="mt-3">
                    <v-col cols="12" md="2"> </v-col>
                    <!-- <v-col cols="12" md="3">
                      <v-card flat class="radius-10 primary">
                        <v-card
                          flat
                          class="
                            white--text
                            d-flex
                            flex-column
                            align-center
                            justify-center
                            radius-10
                            primary
                            connecting-card
                            mx-auto
                          "
                        >
                          <h3>Info</h3>
                          <div>
                            <p class="mt-5 text-center px-4">
                              Upload releases to install from our mobile app.
                            </p>

                            <div class="bold-text mb-4">Key features</div>
                            <div
                              flat
                              class="white-bordered-light radius-10 mb-3"
                            >
                              Upload both Android & iOS at the same time and
                              notify team members.
                            </div>
                            <div flat class="white-bordered-light radius-10">
                              Pre-release info for device compatibility check
                              (UDID, MinOS, SDK,....) for all team members
                              before submitting.
                            </div>
                          </div>
                          <img
                            class="plant"
                            src="/media/svg/dashboard/Plant.svg"
                            alt
                          />
                        </v-card>
                      </v-card>
                    </v-col> -->
                    <v-col cols="12" md="8">
                      <div>
                        <div v-if="getInactiveUserMessage && getIsUserDisabled">
                          <no-data
                            :first-text="
                              getInactiveUserMessage ||
                              'Your membership in this team is inactive, please contact the team owners/admins for more info'
                            "
                          ></no-data>
                        </div>
                        <div v-else>
                          <v-card
                            flat
                            class="radius-10 pr-5 py-7 mx-2"
                            :class="{
                              'pt-15': $vuetify.breakpoint.smAndDown,
                            }"
                          >
                            <create-release
                              no-header
                              from-started
                              class="ml-7 mr-2"
                              @openApp="step = 2"
                              @goNext="nextButtonStep2"
                            />
                            <v-btn
                              absolute
                              top
                              right
                              depressed
                              class="mt-3 text-transform-none primary"
                              @click="$router.push({ name: 'dashboard' })"
                            >
                              I will do it later
                            </v-btn>
                          </v-card>
                        </div>
                      </div>
                    </v-col>
                    <!-- <v-col md="3" cols="12">
                      <download-app></download-app>
                    </v-col> -->
                  </v-row>
                </div>
              </div>
            </div>
          </div>
        </v-card>
        <!--end: Wizard Bpdy-->
      </div>
      <!--end: Wizard-->
      <v-snackbar v-model="notify.show" :color="notify.color">
        {{ notify.message }}
      </v-snackbar>
      <Modal
        :width="$vuetify.breakpoint.mdAndUp ? 1000 : ''"
        :fullscreen="$vuetify.breakpoint.smAndDown"
        @close="openSubscriptionModal = false"
        v-model="openSubscriptionModal"
      >
        <template #message>
          <plan-pricing
            from-modal
            from-get-started
            @close="reset(), (openSubscriptionModal = false)"
          ></plan-pricing>
        </template>
      </Modal>
    </v-card>
  </div>
</template>

<script>
import veeValidate from "@/mixins/veeValidate";
import InvitesList from "@/view/components/Invites/List";
import { mapState, mapGetters, mapMutations } from "vuex";
import { get, isEmpty } from "@/core/services/helper.service";
import { UPDATE_TEAM, GET_TEAM_INFO } from "@/store/team/manageTeam.module";
import CreateAppStep from "@/view/components/App/CreateAppStep.vue";
import CreateRelease from "@/view/components/Releases/CreateRelease";
import CreateTeam from "@/view/components/Team/Create.vue";
import PlanPricing from "@/view/components/Team/PlanPricing";
import { INVITE_BY_EMAIL } from "@/store/team/invite.module";
import { INVITE_BY_URL } from "@/store/team/invite.module";
import InviteMembersPanel from "@/view/components/Invites/InviteMembersPanel.vue";
import DownloadApp from "@/view/components/User/DownloadApp.vue";
// import InfoCard from "@/view/pages/user/get-started/InfoCard.vue";

export default {
  stepNames: {
    "started-team": 1,
    "started-app": 2,
    "started-invite": 3,
    "started-releases": 4,
  },
  data() {
    return {
      step: 1,
      team: {},
      isEmpty,
      haveTeam: true,
      loading: false,
      openSubscriptionModal: false,
      inTeam: null,
      busy: false,
      activeTab: "email",
      notify: {
        show: false,
        message: "",
        color: "",
      },
      switchYearly: true,
      // this 'show' is for the tooltips, there are 3 tooltips in this page, each one has own propertie
      show: null,
    };
  },
  mixins: [veeValidate],
  components: {
    InvitesList,
    CreateAppStep,
    CreateRelease,
    InviteMembersPanel,
    PlanPricing,
    DownloadApp,
    CreateTeam,
    // InfoCard
  },
  computed: {
    ...mapState({
      info: (state) => get(state, "manageTeam.info", ""),
      emailInvites: (state) => get(state, "invite.emailInvites", ""),
      urlInvites: (state) => get(state, "invite.urlInvites", ""),
    }),
    ...mapGetters({
      user: "currentUser",
      getRedirectedFromPlan: "getRedirectedFromPlan",
      getInactiveUserMessage: "getInactiveUserMessage",
    }),
    haveInvites() {
      return isEmpty(this.emailInvites) && isEmpty(this.urlInvites);
    },
  },
  watch: {
    info(val) {
      this.team = { ...val };
    },
    step(val) {
      if (val === 3) {
        this.$store.dispatch(INVITE_BY_EMAIL).catch((err) => {
          this.notifyErrorMessage({ message: err.message });
        });
        this.$store.dispatch(INVITE_BY_URL).catch((err) => {
          this.notifyErrorMessage({ message: err.message });
        });
      }
      if (Object.keys(this.$options.stepNames)[val - 1] === this.$route.name) {
        return;
      }
      this.$router
        .push({
          name: Object.keys(this.$options.stepNames)[val - 1],
        })
        .catch(() => {});
    },
  },
  async created() {
    await this.checkTeam();
  },
  mounted() {},
  methods: {
    ...mapMutations({
      setTeamName: "setTeamName",
    }),
    fetchMember() {
      if (this.info && !isEmpty(this.info)) {
        this.step = 3;
      } else {
        this.notifyInfoMessage("Create or be a in a team first");
      }
    },
    makeActiveType(type) {
      if (type === "email") {
        this.loadEmailInvites();
      } else {
        this.loadUrlInvites();
      }
    },
    checkTeam() {
      this.inTeam = null;
      if (this.loading) return;
      this.loading = true;
      if (this.info && !isEmpty(this.info)) {
        this.team = { ...this.info };
        this.loading = false;
        if (this.$route.name === "get-started") {
          this.step = 1;
        } else {
          this.step = this.$options.stepNames[this.$route.name];
        }
        this.inTeam = true;
        if (this.team && this.team.name) {
          // this.$router.push("/Team");
        }
      } else {
        this.$store
          .dispatch(GET_TEAM_INFO)
          .then(() => {
            this.inTeam = true;
            this.team = { ...this.info };
            if (this.$route.name === "get-started") {
              this.step = 1;
            } else {
              this.step = this.$options.stepNames[this.$route.name];
            }
            this.loading = false;
            if (this.team && this.team.name) {
              // this.$router.push("/Team");
            }
          })
          .catch(() => {
            this.step = 1;
            this.haveTeam = false;
            this.inTeam = false;
            this.loading = false;
          });
      }
    },
    async loadEmailInvites() {
      if (isEmpty(this.emailInvites)) {
        if (this.loading) return;
        this.loading = true;
        await this.$store.dispatch(INVITE_BY_EMAIL).catch();
        this.loading = false;
      } else {
        return;
      }
    },
    async loadUrlInvites() {
      // we have to tabs in Invites one for urls, and one for emails
      this.activeTab = "url";

      // if ther are no urlInvites call API, else don't call teh API cuz we alraedy have the data
      if (isEmpty(this.urlInvites)) {
        if (this.loading) return;
        this.loading = true;
        await this.$store.dispatch(INVITE_BY_URL).catch((err) => {
          this.notifyErrorMessage({ message: err.message });
        });
        this.loading = false;
      } else {
        this.loading = false;
        return;
      }
    },
    gotoPlans() {
      if (this.info && !isEmpty(this.info)) {
        this.step = 2;
      } else {
        this.notify = {
          show: true,
          message: "Please create a team first",
          color: "blue",
        };
      }
    },
    gotoReleases() {
      if (this.info && !isEmpty(this.info)) {
        this.step = 4;
      } else {
        this.notifyInfoMessage("Please create a team first");
      }
    },
    gotoApps() {
      if (this.info && !isEmpty(this.info)) {
        this.step = 2;
      } else {
        this.notifyInfoMessage("Please create a team first");
      }
    },
    updateTeam(dataToPost) {
      if (this.busy) return;
      this.busy = true;
      this.$store
        .dispatch(UPDATE_TEAM, dataToPost)
        .then((response) => {
          this.notifyUserMessage(response.message || "Successfully updated");
          this.step = 2;
          this.setTeamName(dataToPost.name);
          this.busy = false;
        })
        .catch((err) => {
          this.notifyErrorMessage(err.message || "Something went wrong");
          this.busy = false;
        });
    },
    nextButtonStep1() {
      this.step = 2;
    },
    nextButtonStep2() {
      this.step = 3;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~vuetify/src/styles/settings/_variables";
.get-started-top-card {
  min-height: 300px;
  // media breakpoint
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    padding-top: 24px;
    padding-bottom: 15px;
    .wizard-steps {
      margin-top: 15px;
    }
    .wizard-wrapper {
      margin: 7px 0;
    }
  }
  .info {
    min-height: 300px;
  }
}
.connecting-card {
  position: relative;
  .plant-Right {
    position: absolute;
    bottom: 0px;
    right: 25%;
    height: 85%;
  }
  .plant {
    position: absolute;
    top: 0;
    right: -40px;
    height: 80%;
  }
}
</style>
<style lang="scss">
.overflow-hidden {
  overflow: hidden !important;
}
.invites {
  .v-tabs-bar {
    display: none !important;
  }
}
</style>
