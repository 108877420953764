<template>
  <v-card
    class="radius-10 py-14 d-flex justify-center"
    :class="{
      'mx-0 px-3': $vuetify.breakpoint.smAndDown,
      'mx-3': !$vuetify.breakpoint.smAndDown,
    }"
    flat
    @keypress.enter.prevent="createTeam"
    :disabled="busy"
  >
    <v-form style="width: 350px">
      <v-text-field
        id="team-name"
        v-model="formValues.name"
        label="Team name"
        v-validate="'required|min:3|max:27'"
        v-bind="veeValidate('Name', '')"
        counter="27"
        filled
        placeholder="Your team name"
        messages="Can be renamed later"
      ></v-text-field>
      <div class="text-center mt-7">
        <v-btn
          id="stepOneNext"
          :disabled="errors.any() || !formValues.name"
          :loading="busy"
          @click="createTeam"
          class="main wide primary large mx-auto iconic"
        >
          {{ fromSwitchTeam ? "Create" : "Next" }}
          <img
            v-if="!fromSwitchTeam"
            src="/media/svg/icon-arrow-right-light.svg"
            alt
            class="ml-3"
            height="20"
          />
        </v-btn>
      </div>
    </v-form>
  </v-card>
</template>

<script>
import veeValidate from "@/mixins/veeValidate.js";
import {
  CREATE_TEAM_INFO,
  GET_TEAM_INFO,
} from "@/store/team/manageTeam.module";
import { GET_USER_ROLES, UPDATE_USER_TOKEN } from "@/store/users/auth.module";
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "create-team",
  mixins: [veeValidate],
  props: {
    inTeam: {
      type: Boolean,
      default: false,
    },
    fromSwitchTeam: {
      type: Boolean,
      default: false,
    },
    team: {
      type: Object,
      default() {
        return {
          name: "",
          description: "",
        };
      },
    },
  },
  data() {
    return {
      formValues: {},
      busy: false,
    };
  },
  computed: {
    ...mapGetters({
      getRedirectedFromPlan: "getRedirectedFromPlan",
    }),
  },
  created() {
    this.formValues = { ...this.team };
  },
  methods: {
    ...mapMutations({
      updateUserPermission: "updateUserPermission",
    }),
    async createTeam() {
      if (await this.validateAllFields()) {
        if (this.busy) return;
        this.busy = true;
        if (this.fromSwitchTeam) {
          this.formValues.fromSwitch = true;
        }
        if (this.inTeam) {
          this.$emit("update", this.formValues);
        } else {
          this.$store
            .dispatch(CREATE_TEAM_INFO, this.formValues)
            .then((response) => {
              this.$store.dispatch(UPDATE_USER_TOKEN, response.token);
              this.busy = false;
              if (this.fromSwitchTeam) {
                this.$emit("teamCreated");
              } else {
                if (
                  this.getRedirectedFromPlan &&
                  this.getRedirectedFromPlan !== "hobby"
                ) {
                  this.$emit("openSubscriptionModal");
                }
                this.$emit("updateInTeam");
                this.busy = false;

                this.updateUserPermission(response.permissions);
                this.$nextTick(() => {
                  this.$store
                    .dispatch(GET_USER_ROLES)
                    .then(() => {
                      this.connectToSocket();
                      this.$emit("next-step");
                    })
                    .catch((err) => {
                      this.notifyErrorMessage({ message: err.message });
                      this.busy = false;
                    });

                  this.$store.dispatch(GET_TEAM_INFO).catch((err) => {
                    this.notifyErrorMessage(err.message);
                    this.busy = false;
                  });
                });
              }
              this.notifyUserMessage(
                response.message || "Successfully created"
              );
            })
            .catch((err) => {
              this.notifyErrorMessage(err.message || "Something went wrong");
              this.busy = false;
            });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
